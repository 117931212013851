.profile-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}

.profile-box {
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}


a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

body {
    color: #333;
}


.profile-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-info {
    flex: 1;
}

.action-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.action-buttons button {
    margin-bottom: 10px;
}