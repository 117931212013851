.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--background-color);
    height: 80px
}

.header-logo {
    font-size: 24px;
    font-weight: bold;
    color: var(--secondary-color);
}

.header-nav {
    margin-left: 10px;
}

.action-button {
    width: 120px;
    height: 30px;
    line-height: 30px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
    background-color: var(--secondary-color);
    text-align: center;
    color: white; /* Add this line to change the text color to white */
}

.link-style {
    color: white; /* Change the color of the link text to white */
    text-decoration: none; /* Remove the underline from the link */
}