/* Login.css */
.login-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-color-light);
    /* Cool, translucent light dark blue */
}

.login-container {
    padding: 24px;
    background: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 400px;
    /* Adjust based on your preference */
    height: 400px;
}

.login-form {
    margin-top: 70px;
}

.login-form .ant-form-item {
    margin-bottom: 30px;
}

.company-portal {
    bottom: 0px;
    /* Position it at the bottom of the container */
    left: 0px;
    /* Position it at the left of the container */
    text-align: left;
    /* Align the text to the left */
}

.login-form button {
    background-color: var(--primary-color-light);
    /* Replace #yourColor with the color you want */
    border: none;
    /* Remove the default button border */
}