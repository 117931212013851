.register-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-color-light);
}

.register-container {
    padding: 24px;
    background: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 400px;
    /* Adjust based on your preference */
    height: fit-content;
}

.register-form {
    margin-top: 40px;
}

.register-form .ant-form-item {
    margin-bottom: 20px;
}

/* Adjust label width and alignment */
.register-form label {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
}

/* Adjust input width */
.register-form .ant-input {
    width: 100%;
}


.register-form button {
    background-color: var(--primary-color-light); /* Replace #yourColor with the color you want */
    border: none; /* Remove the default button border */
}