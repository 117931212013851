/* Header Styles */

body {
    color: var(--text-color);

}

.dark-blue-header {
    height: 120px;
    background-color: var(--background-color);
    /* Dark blue background */
    color: white;
    /* White text color */

}

.company-name {
    font-size: 45px;
    /* Big and bold company name */
    color: rgb(248, 138, 42);
    /* White text color */
}

.header-nav {
    display: flex;
}

.header-nav Button {
    color: white;
    /* White text color for links */
    margin-left: 30px;
    /* Adjust spacing between links */
    font-size: 20px;
}

.header-nav Button:hover {
    color: rgb(248, 138, 42);
}

/* Remove underline from links */
.header-nav Button {
    text-decoration: none;
}

/* Quick Intro Section Styles */
.quick-intro {
    height: 500px;

    text-align: left;
    /* Center-align text */
    margin-top: 200px;
}

.intro-text .ant-btn-primary {
    margin-left: 0;
    width: 200px;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
}

.quick-intro h1 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 1200;
    text-align: left;
}


.quick-intro .ant-col {
    grid-column-gap: 500px;
    /* Adjust the value as needed */
}

.quick-intro p {
    margin-bottom: 20px;
    /* Adjust margin as needed */
    text-align: left;

    font-size: 15px;
    font-weight: 800;

}

.certificate-image {
    /* Adjust width as needed */
    width: 500px;
    /* Adjust max width as needed */
    height: 400px;

    /* Keep aspect ratio */
}

/* Section Styles */
.section {

    text-align: center;
    /* Center-align text */
}

#row-Title {
    color: var(--text-color);
}

.section h2 {
    margin-bottom: 40px;
    /* Adjust margin as needed */
    font-size: 40px;
}

.ant-row {
    margin-left: 10vw;
    margin-right: 10vw;

}

.ant-card {
    /* Remove border radius */
    background-color: var(--background-color);
    color: white;
    height: 259px;
    margin-left: 10px;
    margin-right: 10px;

}

.ant-card-head-title {
    color: white;
    /* White text color */
    font-size: 20px;

}

#tyonantajalle-point {
    margin-top: 50px;
}

#faq-container {
    background-color: #192039d8;
    height: 500px;

}

#faq-container p {
    font-size: 15px;
}

.FAQ {
    margin-top: 200px;

}

.FAQ .ant-collapse-header {
    background-color: var(--background-color);
    color: white;
    /* Set text color to white for contrast */
}

.FAQ .ant-collapse-header-text {
    color: white;
    font-size: 15px;
    /* Set text color to white for contrast */
}

#hinnasto {
    margin-top: 150px;

}

/* Quick Intro Section Styles */
.quick-intro-secondary {
    height: 500px;
    text-align: left;
    margin-top: 200px;
    /* Added padding to create space between the image and text */
    padding: 0 20px;
}

.intro-text-secondary {}

.intro-text-secondary .ant-btn-primary {
    margin-left: 0;
    width: 200px;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
}

.quick-intro-secondary h1 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: bold;
    text-align: left;
}

.quick-intro .ant-col {
    grid-column-gap: 500px;
}

.quick-intro-secondary p {
    margin-bottom: 20px;
    text-align: left;
    font-size: 15px;
    font-weight: 800;
}

.certificate-image {
    width: 300px;
    /* Adjusted width */
    height: 400px;
    float: left;
    /* Floated the image to the left */
    margin-right: 20px;
    /* Added margin-right to create space between image and text */
}

.intro-text-secondary {}

.pricing-card {
    height: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.pricing-card p{
    text-align: left;
}

.pricing-card:hover {
    border-color: #4A90E2;
}

.pricing-card .ant-card-head {
    background-color: #4A90E2;
    color: white;
    border-radius: 8px 8px 0 0;
}

.pricing-card .ant-card-body {
    padding: 20px;
}

.pricing-card p.price {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

#PricingCardDescription {
    margin-top: 40px;
}
.pricing-card ul {
    padding-left: 0;
    list-style: none;
    text-align: left;
}

.pricing-card li {
    margin-bottom: 10px;
}

#hinnasto {
    margin-left: 10vw;
    margin-right: 10vw;


}



/* landingPage.css */

#ota-yhteytta-main {
    margin-top: 100px;
    width: 100%;
    background-color: #192039d8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align items to the left */
    height: 600px;
}

#ota-yhteytta {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align items to the left */
    margin-left: 200px;
}

#ota-yhteytta h2 {
    text-align: left;
    margin-bottom: 20px;
    color: #ffffff; /* Change text color to white */
}

#ota-yhteytta p {
    text-align: left;
    margin-bottom: 20px;
    color: #ffffff; /* Change text color to white */
}

#ota-yhteytta .ant-input {
    width: 100%;
}

#ota-yhteytta .ant-switch {
    margin: 10px 0 0 0; /* Set margin to 10px on the top and 0 on other sides */
    justify-self: flex-start; /* Align switch to the left */
}

/* Override Ant Design styles for the Switch component */
.ant-switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

#additional-info-input {
    height: 150px;
}


#ota-yhteytta .ant-btn-primary {
    width: 100%;
    margin-top: 10px;
}

.ota-yhteytta-footer-section {
    margin-left: 100px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 400px;

}
.ota-yhteytta-footer-section h2{
    width: 400px;
    text-align: left;
    margin-bottom: 8px;

}

.ota-yhteytta-footer-section p {
    width: 400px;
    text-align: left;
}

.disclaimer {
    padding-top: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #666666;
}

/* Footer Styles */
footer {
    padding: 20px 0;
    background-color: #333;
    color: white;
    text-align: center;
}

/* Footer Styles */
footer {
    padding: 20px 0;
    /* Adjust padding as needed */
    background-color: #333;
    /* Dark background color */
    color: white;
    /* White text color */
    text-align: center;
    /* Center-align text */
}