body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Define color variables */
:root {
    --primary-color: #4831D4;
    /* Brighter primary color for more vibrancy */
    --primary-color-light: #22273B;
    /* Darkened primary color for subtle contrast */
    --secondary-color: #F9772E;
    /* Secondary color remains white for maximum contrast */
    --background-color: #0A0D1A;
    /* A slightly lighter dark background for better contrast with text */
    --text-color: #E3E2F3;
    /* Lighter text color for higher contrast against the dark background */
    --link-color: #4D9FFF;
    /* Brighter link color to stand out more against dark backgrounds */
    --link-hover-color: #1A6FCC;
    /* Darkened link hover state for subtle interaction feedback */
}

/* Global font family */
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary-color-light);
    color: var(--text-color);
}

/* Style for code elements, if needed */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Link styles */
a {
    color: var(--link-color);
    text-decoration: none;
    /* No underline */
}

p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
}


a:hover {
    color: var(--link-hover-color);
    text-decoration: underline;
    /* Underline on hover */
}