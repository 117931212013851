.corp-buy-service {
    font-family: Arial, sans-serif;
  }

  .product-selection {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .product-item {
    padding: 10px;
    margin: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
  }

  .product-item:hover {
    background-color: #e0e0e0;
  }

  .payment-form {
    max-width: 500px;
    margin: auto;
  }

  .submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
  }

  .submit-button:hover {
    background-color: #45a049;
  }
