.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: orange; /* Change background color to orange */
    height: 80px;
    margin-bottom: 40px;
}
.header-logo {
    font-size: 24px;
    font-weight: bold;
    color: var(--secondary-color);
}

.header-nav {
    margin-left: 10px;
}

.action-button {
    width: 120px;
    height: 30px;
    line-height: 30px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
    background-color: var(--secondary-color);
    text-align: center;
    color: white;
}

.link-style {
    color: white;
    text-decoration: none;
}

