.profile-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
}

.profile-container h1 {
    text-align: center;
    margin-bottom: 24px;
}
